import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const IndexPage = () => (
  <Layout>
    <Header /> 

    <header className="masthead_10">
          <br></br>
          <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="bounce-in-top mx-auto my-0">Colleen's Birthday Adventure!</h1>
          <br></br>
          <h2 className="tracking-in-expand mx-auto text-center">
            {/* {config.subHeading} */}
            Refill your your drinks
            <br></br> 
            be sure to measure.
            <br></br> 
            <br></br> 
            It’s time to begin
            <br></br> 
            a quest for treasure.  
            <br></br>
            <br></br>
            Lock the doors
            <br></br>
            and prepare a roadie.
            <br></br>
            <br></br>
            Be sure to leave a treat
            <br></br>
            for our boy Boatie.
            <br></br>
            <br></br>
            It may be tough but don't get moody.  
            <br></br>
            this hunt is called....
            <br></br>
          </h2>        
          <h3 className="text-focus-in font-weight-bold mx-auto">
            Surrender the Booty!</h3>
            <br></br>
          <a href="clue1" className="btn btn-primary">
              Let's do it!
            </a>
            <br></br>
            <br></br>
            <br></br>
        </div>
      </div>
    </header>

    

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
    {/* <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="font-weight-bold text-white mb-4"></h2>
            <p className="text-white-50">
              The center of my world.   
            </p>
          </div>
        </div>
        <img src={ipad} className="img-fluid" alt="" />
      </div>
    </section> */}
  </Layout>
);

export default IndexPage;
